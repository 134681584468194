import type { Translation } from '../i18n-types'
import de_aoa from './de_aoa'
import de_auth from './de_auth'
import de_emails from './de_emails'
import de_org from './de_org'
import de_settings from './de_settings'

const de = {
  ...de_emails,
  ...de_settings,
  ...de_aoa,
  ...de_auth,
  ...de_org,
  approvalStatus: {
    approved: 'Genehmigt',
    archived: 'Archiviert',
    draft: 'Entwurf',
    pending: 'Genehmigung ausstehend',
    withdrawn: 'Geschlossen',
  },
  buttons: {
    closeAoa: 'AOA schließen',
    add: 'Hinzufügen',
    apply: 'Anwenden',
    approve: 'Genehmigen',
    back: 'Zurück',
    cancel: 'Abbrechen',
    close: 'Schließen',
    confirm: 'Bestätigen',
    continue: 'Weiter',
    delete: 'Löschen',
    deselectAll: 'Alle abwählen',
    details: 'Details',
    edit: 'Bearbeiten',
    filter: 'Filter',
    hidePreview: 'Vorschau ausblenden',
    horizontalLayout: 'Horizontale Anordnung',
    import: 'Importieren',
    reloadPage: 'Seite neu laden',
    remove: 'Entfernen',
    resetAll: 'Alle zurücksetzen',
    save: 'Speichern',
    selectAll: 'Alle auswählen',
    showPreview: 'Vorschau anzeigen',
    stopEdit: 'Bearbeitung beenden',
    verticalLayout: 'Vertikale Anordnung',
    random: 'Zufällig',
    addActions: 'Mehrere Actions hinzufügen',
    stopAddingActions: 'Hinzufügen von Actions beenden',
    addOutcomes: 'Outcomes hinzufügen',
    stopAddingOutcomes: 'Hinzufügen von Outcomes beenden',
    viewProfile: 'Profil anzeigen',
    viewTeam: 'Team anzeigen',
  },
  common: {
    and: 'und',
    removed: 'entfernt',
    areYouSure: 'Sind Sie sicher?',
    backToStart: 'Zurück zur Startseite',
    comment: 'Kommentar',
    copiedToClipboard: 'In die Zwischenablage kopiert',
    createdAt: 'Erstellt am',
    description: 'Beschreibung',
    fileUpload: {
      selectFile: 'Datei{{n}} auswählen',
      title: '<>Klicken zum Hochladen</> oder ziehen und ablegen',
    },
    maybe: 'Vielleicht',
    no: 'Nein',
    noComments: 'Keine Kommentare',
    noResults: 'Keine Ergebnisse',
    notFound: 'Nicht gefunden',
    search: 'Suche',
    searchDots: 'Suchen...',
    showLess: 'Weniger anzeigen',
    showMore: 'Mehr anzeigen',
    status: {
      active: 'Aktiv',
      declined: 'Abgelehnt',
      expired: 'Abgelaufen',
      failed: 'Fehlgeschlagen',
      high: 'Hoch',
      inactive: 'Inaktiv',
      invited: 'Eingeladen',
      joined: 'Beigetreten',
      low: 'Niedrig',
      medium: 'Mittel',
      notStarted: 'Nicht gestartet',
      pending: 'Ausstehend',
      terminated: 'Frühzeitig beendet',
      unknown: 'Unbekannt',
    },
    updatedAt: 'Aktualisiert am',
    xMore: '{0} weitere{{r}}',
    yes: 'Ja',
  },
  datetime: {
    at: 'am {date} um {time}',
    createdAtDate: 'Erstellt am {date}',
    lastActivity: 'Letzte Aktivität {date}',
    timeline: 'Zeitachse',
  },
  error: {
    failedToLoad: 'Fehler beim Laden des {0}',
    fileTooLarge: 'Die gewählte Datei ist zu groß.',
    fileUploadFailed: 'Fehler beim Hochladen der Datei {0}',
    invalidFileType: 'Der Dateityp ist nicht zulässig.',
    unknownError: 'Ein unbekannter Fehler ist aufgetreten.',
  },
  errorPages: {
    common: {
      failedToLoadDescription:
        'Versuchen Sie die Seite neu zu laden, falls das Problem weiterhin besteht, wenden Sie sich an den Support.',
      notFoundDescription:
        'Möglicherweise haben Sie die Adresse falsch eingegeben oder die Seite wurde an eine andere URL verschoben. Wenn Sie denken, dass dies ein Fehler ist, wenden Sie sich an den Support.',
    },
    orgNotFound: {
      button: 'Zur Startseite',
      description:
        'Die Organisation, nach der Sie suchen, existiert nicht. Möglicherweise haben Sie die Adresse falsch eingegeben oder die Seite wurde an eine andere URL verschoben. Wenn Sie denken, dass dies ein Fehler ist, wenden Sie sich an den Support.',
      headline: 'Organisation nicht gefunden',
    },
    pageNotFound: {
      headline: 'Seite nicht gefunden',
      message:
        'Die Seite, nach der Sie suchen, existiert nicht. Sie wurde möglicherweise verschoben oder gelöscht.',
    },
    profile: {
      profileFailedToLoad: 'Fehler beim Laden des Profils',
      profileNotFound: 'Profil nicht gefunden',
    },
    team: {
      teamNotFound: 'Team nicht gefunden',
    },
    unauthorized: {
      button: 'Zurück zur Startseite',
      description:
        'Sie haben möglicherweise nicht die erforderlichen Berechtigungen, um diesen Abschnitt anzuzeigen. Wenn Sie denken, dass dies ein Fehler ist, wenden Sie sich an den Support.',
      headline: 'Nicht autorisiert',
    },
  },
  filters: {
    dateRange: {
      endingAt: 'ab {date}',
      exactDate: 'am {date}',
      pickDate: 'Datum auswählen',
      range: '{startDate} - {endDate}',
      startingAt: 'bis {date}',
    },
  },
  homePage: {
    pageHeader: 'Execution Dashboard',
    pageSubheader: 'Willkommen bei GrowthSquare',
    quickLinks: {
      empty: 'Hier ist noch nichts.',
      headline: 'Quick Links',
      subheader: 'Ausgewählte AOAs',
    },
  },
  inputs: {
    searchOrTypeToCreate: 'Suchen oder erstellen',
    units: {
      createCustomUnit: 'Benutzerdefinierte Einheit „{0}“',
      decimal: 'Dezimalzahl',
      dollar: 'Dollar',
      euro: 'Euro',
      number: 'Anzahl',
      percentage: 'Prozent',
      selectBoxLabel: 'Einheit',
    },
  },

  navigation: {
    settings: {
      methodology: 'Methodik',
      notifications: 'Benachrichtigungen',
      organization: 'Organisation',
      personal: 'Konto',
      profile: 'Mein Profil',
      team: 'Team',
    },
    sidebar: {
      aoa: {
        label: 'AOA',
        subItems: {
          backToOverview: 'Zurück zur Übersicht',
          checkin: 'Status Check-In',
          filterPage: 'Dashboard',
          overview: 'Übersicht',
          heatmap: 'Heatmap',
        },
      },
      home: 'Startseite',
      organization: {
        label: 'Organisation',
        subItems: {
          backToOverview: 'Zurück zur Übersicht',
          members: 'Mitglieder',
          overview: 'Übersicht',
        },
      },
      settings: 'Einstellungen',
    },
  },
  notifications: {
    error: 'Fehler',
    success: 'Erfolgreich',
    successMessage: '{0} erfolgreich erstellt',
  },
  table: {
    next: 'Nächste Seite',
    of: 'von',
    page: 'Seite',
    previous: 'Vorherige Seite',
  },
  tags: {
    'gs:highPriority': 'Hohe Priorität',
    'user:you': 'Du',
  },
  validation: {
    emailInvalid: 'Die E-Mail-Adresse ist ungültig',
    isRequired: '{0} ist ein Pflichtfeld',
    onlyLowercaseLettersNumbersAndDashes:
      'Nur Kleinbuchstaben, Zahlen und Bindestriche sind erlaubt',
    passwordMinLength: 'Das Passwort muss mindestens {0} Zeichen lang sein',
    passwordsMustMatch: 'Die Passwörter müssen übereinstimmen',
    tooLong: 'Der Wert ist zu lang (maximal {0} Zeichen)',
    tooShort: 'Der Wert ist zu kurz (mindestens {0} Zeichen)',
    valueCantStartWith: 'Der Wert darf nicht mit {0} beginnen',
  },
} satisfies Translation

export default de
