import type { MantineThemeColors } from '@mantine/core'
import {
  getPeriodForAoa,
  isActiveAoa,
  isExpiredAoa,
  showRelevantAoaForSeries,
} from '@packages/aoa-utils'
import type { TranslationFunctions } from '@packages/i18n/src/i18n-types'
import type { IterationCycle } from '@packages/shapes'
import {
  AoaApprovableStatus,
  type AoaCheckin,
  type AoaInfo,
  type AoaItem,
  AoaProgressStatus,
  type DT,
  type Iteration,
} from '@packages/types'
import type { ApiReturnTypes } from '@types'
import chroma from 'chroma-js'
import _ from 'lodash'

export const sortAoaItemsBySortIndex = <
  T extends { itemId: string; sortIndex?: string },
>(
  items: Readonly<T[]>
) => {
  const sortedItems = [...items]

  return sortedItems.sort((itemA, itemB) =>
    // sort by sortIndex or itemId respectively
    (itemA.sortIndex ?? itemA.itemId).localeCompare(
      itemB.sortIndex ?? itemB.itemId
    )
  )
}

export const sortAoaByPeriodEnd = <
  T extends {
    iteration?: Iteration
  },
>({
  aoas,
  iterationCycles,
}: {
  aoas: T[]
  iterationCycles: ApiReturnTypes['organization']['listIterationCycles']['cycles']
}): T[] => {
  const latestAoaFromSeries = aoas?.sort((aoaA, aoaB) => {
    const periodA = getPeriodForAoa({
      cycles: iterationCycles,
      iteration: aoaA.iteration,
    })
    const periodB = getPeriodForAoa({
      cycles: iterationCycles,
      iteration: aoaB.iteration,
    })

    // periodA and periodB can be null if no iteration is set
    // return the latest first
    if (!periodA) return 1
    if (!periodB) return -1
    // use to sort by date, most recent end date first
    return periodB.end.localeCompare(periodA.end)
  })

  return latestAoaFromSeries
}

export const sortAoaItemsByPriority = <
  T extends {
    periodEnd?: string
    tags?: string[]
  },
>(
  items: Readonly<T[]>
) => {
  const sortedItems = [...items]

  return sortedItems.sort(
    (itemA, itemB) =>
      // sort by has tag gs:highPriority
      (itemB.tags?.includes('gs:highPriority') ? 1 : 0) -
        (itemA.tags?.includes('gs:highPriority') ? 1 : 0) ||
      // then by periodEnd, ascending, undefined last
      (itemA.periodEnd === undefined ? 1 : 0) -
        (itemB.periodEnd === undefined ? 1 : 0) ||
      new Date(itemA.periodEnd ?? 0).valueOf() -
        new Date(itemB.periodEnd ?? 0).valueOf()
  )
}

export const percentageToColor = (percentage: number) =>
  percentage >= 0.8 ? 'green' : percentage >= 0.5 ? 'orange' : 'red'

export const percentageFromColorscheme = (
  percentage: number | null,
  scheme: 'confidence' | 'progress',
  opacity = 1
): string => {
  const percentageClamped = _.clamp(percentage ?? 0, 0, 100)

  let colors: string[] = []
  let saturation = 1

  let color = '#cccccc'

  if (percentage === null) {
    colors = ['#cccccc']
    saturation = 0
  } else if (scheme === 'confidence') {
    colors = ['#660000', '#993300', '#FF8000', '#99B200', '#336600']
    saturation = 4
  } else if (scheme === 'progress') {
    // use colors from dark blue via blue to light blue to gray
    colors = [
      // '#f7fbff',
      // '#deebf7',
      '#c6dbef',
      '#9ecae1',
      '#6baed6',
      '#4292c6',
      '#2171b5',
      '#08519c',
      '#08306b',
      // '#042c5c', // new darker blue color
    ]
    saturation = 1
    if (percentageClamped === 100) color = '#042c5c'
  }

  // Create a scale using chroma with red at 0% and green at 100%
  const scale = chroma.scale(colors)

  // Convert the percentage to a scale from 0 to 1
  color = scale(percentageClamped / 100)
    .saturate(saturation)
    .hex()

  if (opacity < 1) {
    return chroma(color).alpha(opacity).css()
  }

  return color
}

const progressColors = {
  [AoaProgressStatus.Delayed]: 'yellow',
  [AoaProgressStatus.OnTrack]: 'lime',
  [AoaProgressStatus.Cancelled]: 'gray',
  [AoaProgressStatus.Blocked]: 'red',
  [AoaProgressStatus.Done]: 'green',
} satisfies Record<AoaProgressStatus, keyof MantineThemeColors>

export const progressToColor = (
  progress: AoaProgressStatus
): keyof MantineThemeColors => progressColors[progress] ?? 'gray'

export const confidenceToLabel = (
  confidence: number | undefined,
  LL: TranslationFunctions
) => {
  if (confidence === undefined) return null
  if (confidence >= 0.8) return LL.common.status.high()
  if (confidence >= 0.5) return LL.common.status.medium()
  return LL.common.status.low()
}

export const isAoaTextItem = (item: Pick<Readonly<AoaItem>, 'itemType'>) =>
  item.itemType === 'text'

export const isAoaIntentItem = (item: Pick<Readonly<AoaItem>, 'itemType'>) =>
  item.itemType === 'intent'

export const isAoaOutcomeItem = (item: Pick<Readonly<AoaItem>, 'itemType'>) =>
  item.itemType === 'outcome'

export const isAoaActionOutcomeItem = (
  item: Pick<Readonly<AoaItem>, 'itemType'>
) => item.itemType === 'outcome'

/**
 * Check if an item is concluded.
 * An item is concluded if it is done or cancelled.
 */
export const outcomeIsConcluded = (
  checkin: Pick<AoaCheckin, 'status'> | null | undefined
) =>
  Boolean(
    checkin &&
      (checkin.status === AoaProgressStatus.Done ||
        checkin.status === AoaProgressStatus.Cancelled)
  )

export const isRootAoa = (aoaInfo: { level: number }) => aoaInfo.level === 0

export const findRelevantRootAoa = <
  T extends {
    aoaId: string
    iteration?: AoaInfo['iteration']
    level: number
    status: AoaApprovableStatus
  },
>(
  aoas: Readonly<T[]>,
  cycles:
    | ApiReturnTypes['organization']['listIterationCycles']['cycles']
    | undefined
) => {
  // filter root AOAs
  const rootAoas = aoas?.filter(isRootAoa)

  if (rootAoas.length === 1) return rootAoas[0]
  if (rootAoas.length === 0) return undefined

  const firstActiveAoa = rootAoas.find((aoa) =>
    isActiveAoa(aoa, cycles, rootAoas ?? [])
  )

  const notExpiredRootAoa = rootAoas.find(
    (aoa) => !isExpiredAoa({ cycles, iteration: aoa.iteration })
  )

  // first non-expired root AOA or last expired root AOA
  return firstActiveAoa ?? notExpiredRootAoa ?? rootAoas.at(-1)
}

const filterAoasByActive =
  ({ tab, cycles }: { cycles: Array<IterationCycle & DT>; tab: string }) =>
  (aoa: AoaInfo, series: AoaInfo[]) => {
    const isExpired = isExpiredAoa({ cycles, iteration: aoa.iteration })
    if (tab === 'draft' && aoa.status === AoaApprovableStatus.Draft)
      return !isExpired

    const isActive = isActiveAoa(aoa, cycles, series)
    if (
      tab === 'active' &&
      isActive &&
      aoa.status === AoaApprovableStatus.Approved
    )
      return true
    if (
      tab === 'archive' &&
      !isActive &&
      (aoa.status !== AoaApprovableStatus.Draft || isExpired)
    )
      return true
    return false
  }

export const getAoasByLevel = ({
  aoaList,
  iterationCycles,
  tabFilter,
}: {
  aoaList: Array<AoaInfo & DT>
  iterationCycles:
    | ApiReturnTypes['organization']['listIterationCycles']['cycles']
    | undefined
  tabFilter?: 'active' | 'all' | 'archive' | string | undefined
}) => {
  const aoasByLevel = _(showRelevantAoaForSeries(aoaList, iterationCycles))
    .sortBy('title')
    .filter(
      tabFilter === undefined
        ? () => true
        : (aoa) =>
            filterAoasByActive({
              cycles: iterationCycles ?? [],
              tab: tabFilter,
            })(
              aoa,
              aoaList.filter((item) => item.seriesId === aoa.seriesId)
            )
    )
    .groupBy('level')
    .value()

  const rootAoa = findRelevantRootAoa(aoasByLevel[0] ?? [], iterationCycles)

  return {
    rootAoa,
    aoasByLevel: aoasByLevel,
  }
}
