import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Combobox,
  Divider,
  InputBase,
  InputWrapper,
  Loader,
  type MantineRadius,
  type MantineThemeOverride,
  Menu,
  Modal,
  NumberInput,
  Pagination,
  PasswordInput,
  Popover,
  Radio,
  SegmentedControl,
  Select,
  Skeleton,
  Switch,
  TextInput,
  Textarea,
  Tooltip,
  createTheme,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import {
  Colors,
  ThemeConstants,
  convertToMantineColorDefinition,
} from '@packages/corp-id'
import { IconChevronDown } from '@tabler/icons-react'
import { cn } from '@utils'
import { useEffect, useState } from 'react'

const inputFocusClassnames = cn(
  'data-[variant=default]:focus:border-primary-300 data-[variant=default]:focus:ring-2 data-[variant=default]:focus:ring-primary-200'
)

const inputBorderClassnames = cn(
  'data-[variant=default]:border-gray-300 data-[variant=default]:shadow-xs'
)

const inputClassnames = cn(inputBorderClassnames, inputFocusClassnames)

const inputLabelStyles = {
  label: {
    color: 'var(--mantine-color-gray-7)',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    marginBottom: '6px',
  },
  required: {
    color: 'var(--mantine-color-gray-7)',
  },
}

const inputRadius: MantineRadius = 'sm' as const

// Mantive v7
const GrowthSquareTheme = createTheme({
  colors: {
    ...Object.fromEntries(
      Object.entries(Colors.palletes).map(([key, value]) => [
        key,
        convertToMantineColorDefinition(value),
      ])
    ),
  },
  components: {
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        color: 'gray',
        variant: 'white',
      },
    }),
    Badge: Badge.extend({
      styles: {
        root: {
          fontWeight: '700',
          textTransform: 'initial',
        },
      },
    }),
    Button: Button.extend({
      classNames: {
        root: 'data-[variant="white"]:hover:bg-inherit data-[variant="default"]:text-gray-700 data-[variant="white"]:hover:brightness-75',
      },
    }),
    Checkbox: Checkbox.extend({
      classNames: {
        icon: 'peer-checked:text-primary-600 peer-data-[indeterminate=true]:text-primary-600',
        input:
          'border-gray-300 peer checked:border-primary-600 checked:bg-primary-50 data-[indeterminate=true]:bg-primary-50 data-[indeterminate=true]:border-primary-600 cursor-pointer',
        label: 'cursor-pointer',
        inner: 'min-w-[auto]',
      },
    }),
    Combobox: Combobox.extend({
      defaultProps: {
        keepMounted: false,
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      classNames: {
        input: inputClassnames,
      },
      styles: inputLabelStyles,
    }),
    Divider: Divider.extend({
      defaultProps: {
        color: 'gray.2',
      },
    }),
    InputBase: InputBase.extend({
      classNames: {
        input: inputClassnames,
      },
      defaultProps: {
        radius: inputRadius,
      },
      styles: inputLabelStyles,
    }),
    InputWrapper: InputWrapper.extend({
      styles: inputLabelStyles,
    }),
    Loader: Loader.extend({
      defaultProps: {
        'data-usage': 'loader',
      },
    }),
    Menu: Menu.extend({
      classNames: {
        item: 'hover:bg-gray-50 disabled:text-gray-300 disabled:cursor-default',
        itemLabel: 'text-sm font-medium',
        label:
          'font-medium text-gray-500 !pb-0.5 text-[4px] !pt-2.5 leading-[4px]',
      },
      defaultProps: {
        keepMounted: false,
      },
      styles: {
        divider: {
          margin: '0px -6px',
        },
        dropdown: {
          padding: '0px 6px',
        },
        item: {
          fontSize: '14px',
          lineHeight: '16px',
          marginBottom: '2px',
          marginTop: '2px',
          paddingBottom: '9px',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '9px',
        },
        label: {
          fontSize: '12px',
          lineHeight: '14px',
        },
      },
    }),
    MenuItem: Menu.Item.extend({
      defaultProps: { color: 'gray.7' },
    }),
    Modal: Modal.extend({
      styles: {
        title: {
          fontWeight: '700',
        },
      },
    }),
    NumberInput: NumberInput.extend({
      classNames: {
        input: inputClassnames,
      },
      defaultProps: {
        radius: inputRadius,
      },
      styles: inputLabelStyles,
    }),
    Pagination: Pagination.extend({
      classNames: {
        control:
          'data-[active=true]:bg-gray-50 data-[active=true]:text-gray-800 font-medium text-sm text-gray-600  border-none bg-transparent',
        dots: 'text-gray-600',
      },
      defaultProps: {
        size: 40,
      },
    }),
    PasswordInput: PasswordInput.extend({
      classNames: {
        // see index.css, we need to style based on the child using has() selector
        input: inputBorderClassnames,
      },
      defaultProps: {
        radius: inputRadius,
      },
      styles: inputLabelStyles,
    }),
    Popover: Popover.extend({
      defaultProps: {
        keepMounted: false,
      },
    }),
    Radio: Radio.extend({
      classNames: {
        description: 'mt-0 text-sm text-gray-600 font-normal cursor-pointer',
        icon: 'text-primary-600',
        label: 'cursor-pointer font-medium text-gray-700',
        radio:
          '[&:checked]:bg-primary-50 cursor-pointer border-gray-300 [&:checked]:border-primary-600',
      },
    }),
    SegmentedControl: SegmentedControl.extend({
      classNames: {
        control: 'min-w-max',
      },
      styles: {
        // label: { color: 'var(--mantine-color-gray-7)' },
      },
    }),
    Select: Select.extend({
      classNames: {
        input: cn(inputClassnames, 'select-none'),
        option: 'data-[checked=true]:bg-gray-50',
      },
      defaultProps: {
        checkIconPosition: 'right',
        radius: inputRadius,
        rightSection: <IconChevronDown className='text-gray-500' size={20} />,
      },
      styles: {
        ...inputLabelStyles,
      },
    }),
    Skeleton: Skeleton.extend({
      defaultProps: {
        radius: 4,
      },
    }),
    Switch: Switch.extend({
      styles: inputLabelStyles,
    }),
    Textarea: Textarea.extend({
      classNames: {
        input: inputClassnames,
      },
      defaultProps: {
        radius: inputRadius,
      },
      styles: inputLabelStyles,
    }),
    TextInput: TextInput.extend({
      classNames: {
        input: inputClassnames,
      },
      defaultProps: {
        radius: inputRadius,
      },
      styles: inputLabelStyles,
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        keepMounted: false,
      },
    }),
  },
  defaultRadius: inputRadius,
  fontFamily:
    ThemeConstants.fontFamily[
      localStorage.getItem('font')?.toLowerCase() === 'legacy'
        ? 'headings'
        : 'sans'
    ].join(', '),
  fontSizes: {
    lg: '1.125rem',
    md: '1rem',
    sm: '0.875rem',
    xl: '1.25rem',
    xs: '0.75rem',
  },
  headings: {
    fontFamily:
      ThemeConstants.fontFamily[
        localStorage.getItem('font')?.toLowerCase() === 'legacy'
          ? 'headings'
          : 'sans'
      ].join(', '),
    fontWeight: '600',
    sizes: {
      h1: {
        fontSize: '3.75rem',
        fontWeight: '700',
        lineHeight: '4.5rem',
      },
      h2: {
        fontSize: '3rem',
        fontWeight: '700',
        lineHeight: '3.75rem',
      },
      h3: {
        fontSize: '2.25rem',
        fontWeight: '700',
        lineHeight: '2.75rem',
      },
      h4: {
        fontSize: '1.875rem',
        fontWeight: '700',
        lineHeight: '2.375rem',
      },
      h5: {
        fontSize: '1.5rem',
        fontWeight: '600',
        lineHeight: '2rem',
      },
      h6: {
        fontSize: '1.25rem',
        fontWeight: '600',
        lineHeight: '1.875rem',
      },
    },
  },
  lineHeights: {
    ...ThemeConstants.lineHeight,
  },
  primaryColor: 'primary',
  primaryShade: 6,
})

export const useProvideBranding = () => {
  const [mantineTheme, setMantineTheme] = useState<
    MantineThemeOverride | undefined
  >(GrowthSquareTheme)

  useEffect(() => {
    setMantineTheme(GrowthSquareTheme)
  }, [])

  return { mantineTheme }
}
